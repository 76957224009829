import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import styles from './index.module.scss';

//mui components
import { Box, ListItemIcon, Typography } from '@mui/material';

//custom components
import Loader from '../../Components/common/Loader/Loader';

//remixicon
import BarChartLine from 'remixicon-react/BarChartLineIcon';
import FileListLine from 'remixicon-react/FileListLineIcon';

//redux
import { useLazyGetAllMetricsQuery } from '../../store/metrics/metricsApi';
import { MetricType, PostMetricEnum } from '../../store/metrics/metricsTypes';

//notify
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';
import { isAXSEliteENV } from '../../config';

export const Dashboard = () => {
  const [dashboardPostsData, setDashboardPostsData] = useState<{
    [key in PostMetricEnum]: MetricType;
  }>({
    typeBlog: {
      isActive: 0,
      total: 0,
    },
    // typeNews: {
    //   isActive: 0,
    //   total: 0,
    // },
    typeHotTech: {
      isActive: 0,
      total: 0,
    },
    typeSpotlight: {
      isActive: 0,
      total: 0,
    },
    typeHowTo: {
      isActive: 0,
      total: 0,
    },
    allPosts: {
      isActive: 0,
      total: 0,
    },
  });

  const navigate = useNavigate();

  const [getAllMetricsRequest, getAllMetricsResponse] =
    useLazyGetAllMetricsQuery();

  const handleGetMetricTitle = (metric: PostMetricEnum) => {
    switch (metric) {
      case PostMetricEnum.ALL_POSTS:
        return 'All Posts';
      // case PostMetricEnum.NEWS:
      //   return 'News';
      case PostMetricEnum.BLOG:
        return 'Articles';
      case PostMetricEnum.SPOT_LIGHT:
        return 'Spotlight';
      case PostMetricEnum.HOT_TECH:
        return 'Hot tech';
      case PostMetricEnum.HOW_TO:
        return 'How to';
      default:
        break;
    }
  };

  const handleGetMetricRedirectLink = (metric: PostMetricEnum) => {
    switch (metric) {
      case PostMetricEnum.BLOG:
        return '/posts/articles';
      case PostMetricEnum.SPOT_LIGHT:
        return '/posts/spotlight';
      case PostMetricEnum.HOT_TECH:
        return '/posts/hot-tech';
      // case PostMetricEnum.NEWS:
      //   return '/posts/news';
      case PostMetricEnum.HOW_TO:
        return '/posts/how-to';
      default:
        return '/posts';
    }
  };

  useEffect(() => {
    getAllMetricsRequest();
  }, []);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllMetricsResponse;
    if (data && isSuccess) {
      const { typeNews, ...filteredMetrics } = data.data.metrics
        .postsMetrics as any;
      setDashboardPostsData(filteredMetrics);
    }

    showErrorNotify(isError, error);
  }, [getAllMetricsResponse]);

  return (
    <Box>
      <Typography variant="h3" mb="31px">
        Dashboard
      </Typography>
      <Box display="flex" flexWrap="wrap" gap="12px">
        {Object.keys(dashboardPostsData)
          .reverse()
          .filter(item => {
            if (isAXSEliteENV) {
              return (
                item === PostMetricEnum.ALL_POSTS ||
                item === PostMetricEnum.HOW_TO
              );
            } else {
              return item !== PostMetricEnum.HOW_TO;
            }
          })
          .map(metric => (
            <Box
              key={metric}
              position="relative"
              display="flex"
              className={styles.metricDataContainer}
              onClick={() =>
                navigate(handleGetMetricRedirectLink(metric as PostMetricEnum))
              }
            >
              <ListItemIcon
                sx={{ width: '56px', height: '56px' }}
                className={styles.metricIcon}
              >
                {metric === PostMetricEnum.ALL_POSTS ? (
                  <BarChartLine />
                ) : (
                  <FileListLine />
                )}
              </ListItemIcon>

              <Box ml="18px">
                <Typography className={styles.metricTitle}>
                  {handleGetMetricTitle(metric as PostMetricEnum)}
                </Typography>

                <Typography className={styles.metricTotal}>
                  {getAllMetricsResponse.isFetching ? (
                    <Loader size={'17px'} color="#5d6b82" />
                  ) : (
                    getAllMetricsResponse.isSuccess &&
                    dashboardPostsData[metric as PostMetricEnum].total
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
