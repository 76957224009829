import environment from '../../config';
import { ProjectNameEnum } from '../../Utils/constants';
import * as Yup from 'yup';
// const isAXSEliteENV = environment.PROJECT_NAME === ProjectNameEnum.AXS_ELITE;
import { isAXSEliteENV } from '../../config';

const MAX_SIZE = 10 * 1024 * 1024;

const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const MIN_LABEL_LENGTH = 1;
const MAX_LABEL_LENGTH = 30;

const validateFileSize = (file: any) => {
  return (file && file.size < MAX_SIZE) || typeof file === 'string';
};

const validateFileType = (file: any) => {
  return (
    (file && IMAGE_TYPES.some(type => type === file.type)) ||
    typeof file === 'string'
  );
};

const PostFormSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, `Minimum ${2} characters required`)
    .max(150, `Maximum ${150} characters`)
    .required('Required field'),
  type: Yup.string().required('Required field'),
  date: Yup.date().optional().nullable().typeError('Invalid date format'),
  content: Yup.string().optional(),
  image: isAXSEliteENV
    ? Yup.mixed()
        .optional()
        .nullable()
        .test(
          'fileSize',
          'Maximum file size 10 MB',
          file => !file || (file && validateFileSize(file))
        )
        .test(
          'fileFormat',
          'Allowed image formats: JPEG, PNG, GIF',
          file => !file || (file && validateFileType(file))
        )
    : Yup.mixed()
        .required('Image is required')
        .test(
          'fileSize',
          'Maximum file size 10 MB',
          file => !file || (file && validateFileSize(file))
        )
        .test(
          'fileFormat',
          'Allowed image formats: JPEG, PNG, GIF',
          file => !file || (file && validateFileType(file))
        ),
  imageLabel: isAXSEliteENV
    ? Yup.string()
        .min(
          MIN_LABEL_LENGTH,
          `Minimum ${MIN_LABEL_LENGTH} characters required`
        )
        .max(MAX_LABEL_LENGTH, `Maximum ${MAX_LABEL_LENGTH} characters`)
        .optional()
    : Yup.string()
        .min(
          MIN_LABEL_LENGTH,
          `Minimum ${MIN_LABEL_LENGTH} characters required`
        )
        .max(MAX_LABEL_LENGTH, `Maximum ${MAX_LABEL_LENGTH} characters`)
        .required('label is a required field'),
  imageLabelTheme: isAXSEliteENV
    ? Yup.string().optional()
    : Yup.string().required('label theme is a required field'),
});

export default PostFormSchema;
