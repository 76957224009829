import environment, { isAXSEliteENV } from '../../config';

export type listItem = {
  text: menuTitle;
  path: string;
  activeRoutes: string[];
  children?: Array<{ text: string; path: string; activeRoutes: string[] }>;
};

export enum menuTitle {
  DASHBOARD = 'Dashboard',
  POSTS = 'Posts',
  PAGES = 'Pages',
  USERS = 'Users',
  HOT_TECH = 'HotTech',
  SPOTLIGHT = 'Spotlight',
  ARTICLES = 'Articles',
  HOW_TO = 'How To',
}

const commonMenuItems: listItem[] = [
  { text: menuTitle.DASHBOARD, path: '/', activeRoutes: ['/'] },
  {
    text: menuTitle.USERS,
    path: '/users',
    activeRoutes: ['/users'],
  },
];

const postsMenuItems: listItem[] = [
  {
    text: menuTitle.POSTS,
    path: '/posts',
    activeRoutes: [
      '/posts',
      // '/posts/news',
      '/posts/hot-tech',
      '/posts/articles',
      '/posts/spotlight',
      '/posts/how-to',
    ],
    children: [
      ...(isAXSEliteENV
        ? [
            {
              text: menuTitle.HOW_TO,
              path: '/posts/how-to',
              activeRoutes: ['/posts/how-to'],
            },
          ]
        : [
            {
              text: menuTitle.HOT_TECH,
              path: '/posts/hot-tech',
              activeRoutes: ['/posts/hot-tech'],
            },
            {
              text: menuTitle.ARTICLES,
              path: '/posts/articles',
              activeRoutes: ['/posts/articles'],
            },
            {
              text: menuTitle.SPOTLIGHT,
              path: '/posts/spotlight',
              activeRoutes: ['/posts/spotlight'],
            },
          ]),
    ],
  },
];

const pagesMenuItem: listItem = {
  text: menuTitle.PAGES,
  path: '/pages',
  activeRoutes: ['/pages'],
};

export const menuList: listItem[] = [
  ...commonMenuItems,
  ...(environment.IS_HIDE_POSTS_NAVIGATION ? [] : postsMenuItems),
  ...(isAXSEliteENV ? [] : [pagesMenuItem]),
];
