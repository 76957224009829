export enum SortBy {
  BY_TITLE = 'byTitle',
  BY_CREATED_AT = 'byCreatedAt',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ImageLabelTheme {
  DARK = 'Dark',
  LIGHT = 'Light',
}

export enum PostTypeEnum {
  BLOG = 'Blog',
  // NEWS = 'News',
  HOT_TECH = 'HotTech',
  SPOTLIGHT = 'Spotlight',
  HOW_TO = 'HowTo',
}

export type PostTypeProperty =
  | PostTypeEnum.BLOG
  | PostTypeEnum.HOT_TECH
  | PostTypeEnum.SPOTLIGHT
  | PostTypeEnum.HOW_TO;

export const postTypeProperty = [
  'Articles',
  PostTypeEnum.HOT_TECH,
  PostTypeEnum.SPOTLIGHT,
  PostTypeEnum.HOW_TO,
];
export const postLabelTheme = [ImageLabelTheme.LIGHT, ImageLabelTheme.DARK];
