// React
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

// Routes
import PublicRoute from './routers/PublicRoute';
import PrivateRoute from './routers/PrivateRoute';
import SuperAdminRoute from './routers/SuperAdminRoute';
import ShouldPasswordUpdateProvider from './routers/ShouldPasswordUpdateProvider';

// Pages
import {
  AddPost,
  Dashboard,
  LoginPage,
  PageCreation,
  Pages,
  Posts,
  ResetPasswordSendEmailPage,
  UpdatePasswordPage,
  Users,
  Post,
  PrivateUpdatePasswordPage,
} from './Pages';

// Components
import Header from './Components/Header/Header';

// Types
import { PostTypeEnum } from './interfaces/requestParams';

// Styles
import './App.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { isAXSEliteENV } from './config';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* PRIVATE ROUTES */}
        <Route
          path="/"
          element={
            <PrivateRoute
              redirectTo="/login"
              children={
                <ShouldPasswordUpdateProvider>
                  <Header />
                </ShouldPasswordUpdateProvider>
              }
            />
          }
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/posts" element={<Posts />} />

          {isAXSEliteENV ? (
            <>
              <Route
                path="/posts/how-to"
                element={<Posts postsType={PostTypeEnum.HOW_TO} />}
              />
            </>
          ) : (
            <>
              <Route
                path="/posts/hot-tech"
                element={<Posts postsType={PostTypeEnum.HOT_TECH} />}
              />
              <Route
                path="/posts/articles"
                element={<Posts postsType={PostTypeEnum.BLOG} />}
              />
              <Route
                path="/posts/spotlight"
                element={<Posts postsType={PostTypeEnum.SPOTLIGHT} />}
              />
            </>
          )}

          {/* <Route
            path="/posts/news"
            element={<Posts postsType={PostTypeEnum.NEWS} />}
          /> */}
          <Route path="/post-edit/:id" element={<AddPost isEdit />} />
          <Route path="/post-creation" element={<AddPost />} />
          <Route path="/post/:id" element={<Post />} />

          {!isAXSEliteENV && (
            <>
              <Route path="/pages" element={<Pages />} />
              <Route path="/page-creation" element={<PageCreation />} />
              <Route path="/page-edit/:id" element={<PageCreation isEdit />} />
            </>
          )}

          <Route
            path="/"
            element={<SuperAdminRoute redirectTo="/" children={<Outlet />} />}
          >
            <Route path="/users" element={<Users />} />
          </Route>
        </Route>
        <Route
          path="/"
          element={<PrivateRoute redirectTo="/login" children={<Outlet />} />}
        >
          <Route
            path="/private-update-password"
            element={<PrivateUpdatePasswordPage />}
          />
        </Route>

        {/* PUBLIC ROUTES */}
        <Route
          path="/"
          element={<PublicRoute redirectTo="/" children={<Outlet />} />}
        >
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/reset-password-send-email"
            element={<ResetPasswordSendEmailPage />}
          />
          <Route
            path="/update-password/:token"
            element={<UpdatePasswordPage />}
          />
        </Route>

        {/* NOT FOUND */}
        <Route path="*" element={<div>Not found page</div>} />
      </Routes>
    </Router>
  );
};

export default App;
